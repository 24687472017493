import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ResetPasswordRequestModel } from '../../../models/account/reset-password-request.model';

export const resetPasswordFeaturesActions = createActionGroup({
  source: 'Login Api',
  events: {
    'Reset Password': props<Omit<ResetPasswordRequestModel, 'recaptchaToken'>>(),
    'ReCaptcha Failed': emptyProps(),
    'Reset State': emptyProps(),
  },
});
